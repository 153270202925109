import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/alirun/Work/Blockeys/ofi-landing/src/layouts/blogPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`As the importance of side-chains increases in the DeFi, we are super excited to present Bridge Protection for the largest and most solid side-chains. Based on the Opium CDS contract, our insurance is decentralized and tradable; it can be purchased as a token or sold via Opium Staking.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://opium.finance/insurance"
      }}>{`Click here to stake into or purchase Opium Bridge Protection`}</a></p>
    <hr></hr>
    <h2 {...{
      "id": "side-chains-vs-layer-2--the-importance-of-the-bridge",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-chains-vs-layer-2--the-importance-of-the-bridge",
        "aria-label": "side chains vs layer 2  the importance of the bridge permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side-chains vs Layer-2 — the importance of the bridge`}</h2>
    <p>{`Layer-2 solutions (L2) typically use cryptography and game theory (optimistic rollups) to ensure that off-chain transactions are properly implemented into the main net. Side-chains are usually forks of Ethereum or other blockchains, which can provide users with lower gas fees due to the specific advantages and transaction amount. There is a bridge that connects such side-chain with the main net. `}<strong parentName="p">{`It is safe to say that the side-chain is as good as its bridge.`}</strong></p>
    <p>{`Side-chains are fantastic mechanisms that allow moving part of the assets and protocols out of the main Ethereum chain; however, not everybody understands that all assets of the side-chain are owned by the “bridge contract” on the Ethereum main-net.`}</p>
    <p>{`There are several ways to secure bridges, create decentralized bridges and distribute the governance among trusted groups of people. We believe that it is the right approach and logical development for this promising scaling solution.`}</p>
    <p>{`At the same time, we are happy to introduce our own solution to the issue, Opium Bridge Protection–tokenized and tradable decentralized insurance. Anyone bullish on the Bridge can stake money and earn fees from selling insurance, while users who seek protection can buy monthly coverage.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3b431cc5211df7ce3ec5cc1393130bc8/5143c/Scheme2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5UlEQVR42o2QPW/TUBSGsyEhNSS+19dxYtzEjn39kTZ1W1PSBhqStKFVk4iBtiDUAdYKIiSEKoaoIzDCABu/gIG9I/wA1ImFFak/4uXYyVC+GR6dj/ue9557M1z18b8w7lH0KPrIMwnGHMIl5AQ6z/zb4HxvYsaFB8OYBy/FRETUwZOaNFPD4Ddm5/Kf+qIgUQtWINweuNWEaid5PDFMtmCKTUKJvOKmKLR+OiwCCGJWnwNPdNMt84pENlcFy9vgikN1FZdyDmmSDUUIXu1C6POoVGLY1goMM0qNZy4YqC9vYHf8HEuNLWQvlsG1EObsEirlGBrNKHSRIeqo6DVaIDE0GxB330Gzr8Pz24gXb8F011D21rExOsbRp1O8+nyGxyen2BmNcdlchhN2UVvow5L0XOca1jbHOG49gVqcI0NrHbx5CN1tw4kGCFcPoJdXcfvpCxx9OcPLj9/w+uQrDt9+wJX+AxTpYjsawm/cg+m1UAx2YA4fwu08glq6SoZOl57cgSY3UfC3oHkJPWj0DTd2Rzh48x6t+8+gWy3MlJoQpBOyR9xMc5VmRbUN5nbSr8vwYAA1HIAH/R9gRNbuoSC3kaPISCdqw190PJ0lj6lPRl3Yx58Qi3fAo0lUo6S3h7/pk/PvS5IzQAAJ4MIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Opium decentralized insurance at a glance: bullish users stake money in the bridge pool to earn fees, while users seeking protection buy monthly coverage to hedge risks.",
              "title": "Opium decentralized insurance at a glance: bullish users stake money in the bridge pool to earn fees, while users seeking protection buy monthly coverage to hedge risks.",
              "src": "/static/3b431cc5211df7ce3ec5cc1393130bc8/c1b63/Scheme2.png",
              "srcSet": ["/static/3b431cc5211df7ce3ec5cc1393130bc8/5a46d/Scheme2.png 300w", "/static/3b431cc5211df7ce3ec5cc1393130bc8/0a47e/Scheme2.png 600w", "/static/3b431cc5211df7ce3ec5cc1393130bc8/c1b63/Scheme2.png 1200w", "/static/3b431cc5211df7ce3ec5cc1393130bc8/d61c2/Scheme2.png 1800w", "/static/3b431cc5211df7ce3ec5cc1393130bc8/97a96/Scheme2.png 2400w", "/static/3b431cc5211df7ce3ec5cc1393130bc8/5143c/Scheme2.png 9918w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Opium decentralized insurance at a glance: bullish users stake money in the bridge pool to earn fees, while users seeking protection buy monthly coverage to hedge risks.`}</figcaption>{`
  `}</figure></p>
    <h1 {...{
      "id": "staking-return--high-apr-for-the-same-risk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#staking-return--high-apr-for-the-same-risk",
        "aria-label": "staking return  high apr for the same risk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Staking return — high APR for the same risk`}</h1>
    <p>{`When you stake money into the Bridge staking pool (against a specific Bridge), you earn all the fees from issuing coverage. By placing funds into the pool, you accept the risks associated with the Bridge (`}<strong parentName="p">{`just like you do when transferring the funds to a side-chain`}</strong>{`) and receive an APR for it!`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c2373f9753548e5b4e0bccab733f375f/8a23a/Scheme1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "38.666666666666664%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAWJAAAFiQFtaJ36AAABvElEQVR42m2Py27aQBSGeYPUHo9tAjgEYWPMxRgogUCglEudQq5SQkKrSo0UqYt221bqvk/Qh+mqr/Z17IRN1cWn/5z555z5J2PIKv8idmr4aLqHLipKK2kvjN09P+1399Iz5WX+t3C3VJoBzkGb/VyTghNhqF4TPkUnwA9naOU5ZmmAWekjC02E8jJCd0lRryRJEpLBVNVZrT6mGowIlErDe0qp0hlWHWE1EGYNw1a1mkmCZAzvDdKdky2oJMWXZBWaHmAXOpiFLnuilqIbNWR5gZnrknO6ZPPKt0OkFaoHGpjZCGm3yMjRZ6zxFxqtJcPBO8L2mmj9keXvP0y+/cTK96mEMU5+gH31C7ezYXp8w2R0j+dNub7+xMPDV6q+CmX31MLuB8zjR+xOTGm+xe6f4U23HD3+4KB9gV6eUXKXVK0hpfF33FLMcD/mJL/Gz7/idW9DfPKexuECLztWXw7OMGrnyOYFcniDjK7QvFP2nBmiskKvn+MWYwbmnJ69oGfNGVjLlKRuiymRNknrI+VnZOsO2dogQ0Xj9kmjO8zOFlN5Wvue6PCSyxcxK/2UtWL1TFKfibcp6+f+LxWA8QAx5AlYAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Accepting the risks associated with the Bridge and receiving an APR for it vs simply transferring the funds to a side-chain",
              "title": "Accepting the risks associated with the Bridge and receiving an APR for it vs simply transferring the funds to a side-chain",
              "src": "/static/c2373f9753548e5b4e0bccab733f375f/c1b63/Scheme1.png",
              "srcSet": ["/static/c2373f9753548e5b4e0bccab733f375f/5a46d/Scheme1.png 300w", "/static/c2373f9753548e5b4e0bccab733f375f/0a47e/Scheme1.png 600w", "/static/c2373f9753548e5b4e0bccab733f375f/c1b63/Scheme1.png 1200w", "/static/c2373f9753548e5b4e0bccab733f375f/d61c2/Scheme1.png 1800w", "/static/c2373f9753548e5b4e0bccab733f375f/97a96/Scheme1.png 2400w", "/static/c2373f9753548e5b4e0bccab733f375f/8a23a/Scheme1.png 5239w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Accepting the risks associated with the Bridge and receiving an APR for it vs simply transferring the funds to a side-chain`}</figcaption>{`
  `}</figure></p>
    <p>{`You can stake and unstake your funds monthly on the rebalancing day and resell your position in the secondary market at any time.`}</p>
    <p><strong parentName="p">{`Everybody from the Opium community who places their position in the AMM of Curve Finance will be eligible for a liquidity mining program.`}</strong></p>
    <h2 {...{
      "id": "terms-of-protection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#terms-of-protection",
        "aria-label": "terms of protection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Terms of protection`}</h2>
    <p><strong parentName="p">{`Buy-side:`}</strong>{` monthly coverage, you decide the nominal you want to cover and pay a corresponding fee. If that money is lost on the Bridge due to the fault or hack, you will get a proportional amount of the covered nominal.`}</p>
    <p><strong parentName="p">{`Staking pool:`}</strong>{` you can stake at any time and unstake monthly. You can resell your position during the month via Curve Finance AMM. You earn fees paid by purchasers of the protection. If protection buyers lose money on the Bridge due to a fault or hack, you’ll lose your stake.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      